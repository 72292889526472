import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import InfoBox from "../components/InfoBox";
import { PageContainer, PageContent, Section } from "../styles";
import { graphql, useStaticQuery } from "gatsby";
import PageHeader from "../components/PageHeader/PageHeader";
import Footer from "../components/Footer";

export default function Appointment() {
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Appointment - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Appointment" description="Book an appointment" />
        <PageContent>
          <div className="container">
            <div className="row">
              <div className="col col-lg12">
                <Section>
                  <InfoBox>
                    <h2>How do I make an appointment?</h2>
                    <p>
                      You can contact me directly by phone, email or text to
                      book an initial consultation
                    </p>
                    <p>
                      <strong>Opening Times:</strong> 8am - 8pm Monday - Friday
                    </p>
                    <p>Saturday 8am - 12pm</p>
                    <hr />
                    <h2>Treatment Fees - What are they?</h2>
                    <p>
                      <strong>£50 per session</strong>, sessions last
                      approximately 1 hour.
                    </p>
                    <p>
                      <strong>£80 for couples therapy. </strong>
                    </p>
                    <p>
                      <strong>NHS Discount</strong> is available if applicable.
                    </p>
                    <p>
                      <strong>For low income clients</strong>, we can make
                      arrangements.
                    </p>
                    <hr />
                    <h2>How can I pay?</h2>
                    <p>You can pay by cash or bank transfer..</p>
                    <hr />
                    <h2>What happens if you miss an appointment?</h2>
                    <p>
                      If you cancel an appointment within 24 hours, a charge may
                      apply.
                    </p>
                  </InfoBox>
                </Section>
              </div>
            </div>
          </div>
        </PageContent>
      </PageContainer>
      <div style={{ background: "rgb(30, 36, 54)" }}>
        <Footer contactForm={true} />
      </div>
    </Layout>
  );
}
